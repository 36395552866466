<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="35%"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
  >
    <div class="examinationPaper">
      <div class="title">{{ title }}</div>
      <el-form ref="form" label-position="top" :model="form" label-width="80px">
        <el-form-item
          :label="'第' + (index + 1) + '题' + '  ' + item.subjectName"
          v-for="(item, index) in topicList"
          :key="index"
        >
          <div
            v-if="item.questionTypeName == '多选题'"
            style="display: flex;flex-wrap: wrap;flex-direction: column;"
          >
            <el-checkbox-group
              v-model="item.datas"
              v-for="(items, indexs) in item.options"
              :key="indexs"
              style="margin-right: 20px"
            >
              <el-checkbox
                :disabled="isDisabled"
                :label="items.answerLetter"
                name="type"
                >{{
                  items.answerLetter + "、" + items.answerContent
                }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
          <div
            v-if="item.questionTypeName == '单选题'"
            style="display: flex;flex-wrap: wrap;flex-direction: column;"
          >
            <el-radio-group
              v-model="item.datas"
              v-for="(items, indexs) in item.options"
              :key="indexs"
              @change="radioChange"
              style="margin-right: 20px"
            >
              <el-radio :disabled="isDisabled" :label="items.answerLetter">{{
                items.answerLetter + "、" + items.answerContent
              }}</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="footerBtns">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="enterBtn">提 交</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        resource: 0
      },
      isDisabled: false,
      topicList: [],
      title: "",
      examId: 0
    };
  },
  watch: {},
  created() {},
  methods: {
    cancel() {
      this.dialogVisible = false;
      this.isDisabled = false;
    },
    enterBtn() {
      var arr = [];
      for (let i = 0; i < this.topicList.length; i++) {
        if (this.topicList[i].datas != [] && this.topicList[i].datas != "0") {
          if (this.topicList[i].datas instanceof Array) {
            arr.push(this.topicList[i].datas.join(""));
          } else {
            arr.push(this.topicList[i].datas);
          }
        }
      }
      if (arr.length != this.topicList.length) {
        this.$EL_MESSAGE("还有题目未答完");
        return;
      }
      console.log(arr);
      protocol.param_examinationSubmit.param.examId = this.examId;
      protocol.param_examinationSubmit.param.answers = arr;
      protocol.param_examinationSubmit.param.answerTime = new Date().getTime();
      http.postFront(protocol.param_examinationSubmit).then(response => {
        var { code, value, message } = response.data;
        if (Number(code) === 0) {
          this.isDisabled = true;
          this.getIsPass();
        }
      });
    },
    radioChange(val) {
      console.log(this.topicList);
    },
    // 查询考试是否通过
    getIsPass() {
      http.postFront(protocol.param_questinbankfrontquery).then(response => {
        var { code, value, message } = response.data;
        if (Number(code) === 0) {
          if (value) {
            if (value[0].answerIsTrue) {
              // 已通过
              this.$emit("enterInto");
              this.dialogVisible = false;
              localStorage.setItem("examination_w", true);
            } else {
              // 未通过
              this.isDisabled = false;
              this.$EL_MESSAGE("您的答案不正确,请重新作答");
              localStorage.setItem("examination_w", false);
            }
          } else {
            // 未通过
            this.isDisabled = false;
            this.$EL_MESSAGE("您的答案不正确,请重新作答");
            localStorage.setItem("examination_w", false);
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 查询所有考试信息
    getAllSearchQuestinbankfrontquery() {
      var that = this;
      http
        .postFront(protocol.param_allSearchQuestinbankfrontquery)
        .then(response => {
          var { code, value, message } = response.data;
          if (Number(code) === 0) {
            if (value.length != 0) {
              that.getSearchQuestinbankfrontquery(value[0].examId);
              that.title = value[0].examName;
              that.examId = value[0].examId;
            } else {
              this.$EL_MESSAGE("暂无测评试题");
            }
          }
        });
    },
    // 查询试卷
    getSearchQuestinbankfrontquery(id) {
      protocol.param_searchQuestinbankfrontquery.param.examId = id;
      http
        .postFront(protocol.param_searchQuestinbankfrontquery)
        .then(response => {
          var { code, value, message } = response.data;
          if (Number(code) === 0) {
            if (value.titles.length != 0) {
              for (let i = 0; i < value.titles.length; i++) {
                if (value.titles[i].questionTypeName == "多选题") {
                  value.titles[i].datas = [];
                } else if (value.titles[i].questionTypeName == "单选题") {
                  value.titles[i].datas = "0";
                }
                if (i == value.titles.length - 1) {
                  this.topicList = value.titles;
                  this.dialogVisible = true;
                }
              }
            } else {
              this.$EL_MESSAGE("暂无测评试题");
            }
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    isShow() {
      this.getAllSearchQuestinbankfrontquery();
    },
    handleClose() {
      this.dialogVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog {
  height: 80%;
}
/deep/ .el-radio-group {
  margin: 0 2vw 1vw 0;
}
/deep/ .el-checkbox__input {
  display: flex;
  // align-items: center;
  padding-top: 2.8px;
}
/deep/ .el-checkbox {
  display: flex;
}
/deep/ .el-checkbox,
.el-checkbox__input {
  white-space: normal;
  word-break: break-all;
  margin: 0 2vw 1vw 0;
}
/deep/ .el-radio__label {
  white-space: normal;
  word-break: break-all;
  margin: 0 2vw 1vw 0;
}
/deep/ .el-form-item__content {
}
/deep/ .el-dialog {
  min-height: 80%;
  // min-width: 650px !important;
  overflow: hidden;
  overflow-y: auto;
}
/deep/ .el-dialog::-webkit-scrollbar {
  width: 4px;
}
/deep/ .el-dialog::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
/deep/ .el-dialog::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
.examinationPaper {
  .title {
    width: 35vw;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    position: fixed;
    margin-top: -60px;
    left: 32.5vw;
    right: 0;
    padding: 20px 0;
    background: #fff;
    z-index: 1000;
  }
}
.footerBtns {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
</style>

<template>
  <div class="certificationTypePage">
    <div class="identityPopup">
      <div class="title">请选择您认证的身份类型</div>
      <div class="middleBox">
        <!-- <div
          class="boxItem"
          :style="flag == 1 ? 'border: 1px solid #1889FB' : false"
          @click="itemClick(1)"
        >
          <div class="box_top">
            <div class="b_top">
              <img src="../../assets/images/myBox/gr.png" alt="" />
            </div>
            <div class="b_middle">个人</div>
          </div>
          <div class="b_bottom">
            <div class="bb_flag"></div>
            <div class="bb_text">需要提供身份证进行验证</div>
          </div>
        </div> -->
        <div
          class="boxItem"
          :style="flag == 2 ? 'border: 1px solid #1889FB' : false"
          @click="itemClick(2)"
        >
          <div class="box_top">
            <div class="b_top">
              <img src="../../assets/images/myBox/gs.png" alt="" />
            </div>
            <div class="b_middle">企业</div>
          </div>
          <div class="b_bottom">
            <div class="bb_flag"></div>
            <div class="bb_text">
              <div class="bbt_top">需要提供法人身份证</div>
              <div class="bbt_bottom">企业营业执照进行验证</div>
            </div>
          </div>
        </div>
      </div>
      <div class="btns">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="popupEnter">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
/* global protocol http Toast*/

export default {
  components: {},
  data() {
    return {
      isLoading: false,
      flag: 2,
      certifiy: "未认证"
    };
  },
  mounted() {
    // this.queryCertificationType();
    // this.getBDcertifiy();
  },
  methods: {
    getToken() {
      const that = this;
      http
        .postFront(protocol.param_getVerifyToken)
        .then(response => {
          const { code, message, value } = response.data;
          if (code === 0 || code === "0") {
            this.verifyToken = value.result.verifyToken;
            // setTimeout(() => {
            //   this.$refs.submitFrom.submit()
            //   this.isLoading = false
            // }, 2000)
            const u = navigator.userAgent;
            const iPhone = u.indexOf("iPhone") > -1 || u.indexOf("Mac") > -1; // iphone
            const iPad = u.indexOf("iPad") > -1; // iPad
            const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
            if (isiOS || iPhone || iPad) {
              this.sucessfulUrl = window.g.BD_PHONEPAGE + "/1";
              this.failUrl = window.g.BD_PHONEPAGE + "/2";
              this.$nextTick(() => {
                that.$refs.submitFrom.submit();
                that.isLoading = false;
              });
            } else {
              this.$nextTick(() => {
                that.$refs.submitFrom.submit();
                that.isLoading = false;
              });
            }
          } else {
            Toast(message);
          }
        })
        .catch(response => {
          this.tokenTimer = setTimeout(() => {
            this.getToken();
          }, 1000);
        });
    },
    getBDcertifiy() {
      http.postFront(protocol.param_getIdentificationInfo).then(response => {
        const { code, message, value } = response.data;
        this.isBdRetun = true;
        if (code === "0" || code === 0) {
          if (
            value !== null &&
            JSON.stringify(value) !== "{}" &&
            value !== "0"
          ) {
            this.BDcertifiy = "已认证";
          } else {
            this.BDcertifiy = "未认证";
          }
          if (this.isClickCer) {
            this.isLoading = false;
            this.toPersonInfo();
          }
        } else {
          Toast(message);
        }
      });
    },
    // 认证类型查询
    queryCertificationType() {
      http.postFront(protocol.param_queryCertificationType).then(response => {
        var { value, code, message } = response.data;
        if (Number(code) === 0) {
          if (value && value.auditType) {
            // 已选择认证类型
            if (value.auditType === "P" || value.auditType === "O") {
              //   this.$router.push('/joinNonEnterprise')
            }
          } else {
            // 未选择认证类型
            console.log(message);
          }
        } else {
          Toast(message);
        }
      });
    },
    itemClick(num) {
      this.flag = num;
    },
    cancel() {
      this.$emit("cancel");
    },
    certificationTypeSave(type) {
      protocol.param_certificationTypeSave.param.auditType = type;
      http.postFront(protocol.param_certificationTypeSave).then(response => {
        var { code, message } = response.data;
        if (Number(code) === 0) {
          //   this.$router.push('/joinNonEnterprise')
        } else {
          Toast(message);
        }
      });
    },
    popupEnter() {
      localStorage.setItem("identityFlag_w", this.flag);
      if (this.flag === 1) {
        // 个人
        this.certificationTypeSave("P");
        this.$emit("popupEnter");
      } else if (this.flag === 2) {
        // 公司
        this.$emit("popupEnter");
        this.certificationTypeSave("O");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.identityPopup {
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
  min-height: 400px;
  .title {
    font-size: 14px;
  }
  .middleBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .boxItem {
    margin: 0 60px;
    cursor: pointer;
    margin-top: 15px;
    border: 1px solid #cdcdcd;
    border-radius: 2vw;
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    justify-content: space-between;
    .box_top {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .b_top {
        width: 100px;
        height: 100px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .b_middle {
        color: #1889fb;
        font-size: 16px;
      }
    }
    .b_bottom {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      .bb_flag {
        width: 12px;
        height: 12px;
        background-color: #979797;
        border-radius: 50%;
        border: 2px solid #fff;
        box-shadow: 2px 2px 5px 2px #979797;
        margin-right: 10px;
      }
      .bb_text {
        font-size: 14px;
      }
    }
  }
  .btns {
    // margin-top: 55vw;
    // position: fixed;
    // bottom: 10px;
    // left: 0;
    width: 50%;
    margin-top: 10px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    .btn1 {
      width: 100px;
      border-radius: 20vw;
      background-color: #dddddd;
      color: #fff;
    }
    .btn2 {
      width: 100%;
      // border-radius: 20vw;
      background-color: #1889fb;
      color: #fff;
      //   width: 80%!important;
      height: 40px !important;
      line-height: 40px !important;
      border-radius: 5px !important;
    }
  }
}
</style>
